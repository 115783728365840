import { IconButton, IconButtonProps } from "@mui/material";
import { FC, ReactNode } from "react";

interface Props extends IconButtonProps {
  children: ReactNode;
  edge: "end" | "start";
}

const NavButton: FC<Props> = ({ edge, children, ...props }) => {
  return (
    <IconButton
      size="large"
      edge={edge}
      color="inherit"
      aria-label="menu"
      sx={{
        ml: edge === "start" ? 0 : 2,
        mr: edge === "end" ? 0 : 2,
      }}
      {...props}
    >
      {children}
    </IconButton>
  );
};

export default NavButton;
