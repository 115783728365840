import { useContext } from "react";
import { sortBy } from "lodash";

import { CartContext } from "../../contexts/CartContext";
import { ICartContext } from "../../types/cart";
import CartItem from "../CartItem";
import styles from "./styles.module.css";
import { useParams } from "react-router-dom";

const Items = () => {
  const { getCart } = useContext(CartContext) as ICartContext;
  const { slug } = useParams();

  const cart = getCart(slug);
  const items = Object.keys(cart).map((productId) => cart[parseInt(productId)]);
  const sortedItems = sortBy(items, "product.name");

  return (
    <div>
      <div className={styles.items}>
        {sortedItems.map((item) => (
          <CartItem key={item.product.id} item={item} />
        ))}
      </div>
    </div>
  );
};

export default Items;
