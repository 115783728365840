import { useQuery } from "@tanstack/react-query";

import useFetch from "../lib/useFetch";
import { useContext } from "react";
import { DeliveryContext } from "../contexts/DeliveryContext";
import { IDeliveryContext } from "../types/delivery";

const API = process.env.REACT_APP_API;

const useUrbanPartner = () => {
  const { address, setAddress } = useContext(
    DeliveryContext,
  ) as IDeliveryContext;
  const fetch = useFetch(`${API}/api/urban-partner`);

  return useQuery({
    queryKey: ["urban-partner"],
    queryFn: fetch,
    onSuccess: (data) => {
      if (data) {
        if (address === null) {
          setAddress(data.address);
        }
      }
    },
  });
};

export default useUrbanPartner;
