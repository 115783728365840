import { useMutation } from "@tanstack/react-query";

import { useSearchParams } from "react-router-dom";

const API = process.env.REACT_APP_API;

const useConfirmEmail = () => {
  const [searchParams] = useSearchParams();
  const mutationFn = async () => {
    const response = await fetch(`${API}/api/email-confirmation`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token: searchParams.get("token") }),
    });
    return await response.json();
  };

  return useMutation({
    mutationFn,
  });
};

export default useConfirmEmail;
