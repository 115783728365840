import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

const API = process.env.REACT_APP_API;

const useSeed = () => {
  const { slug } = useParams();
  const queryFn = async () => {
    const response = await fetch(`${API}/api/seed/${slug}`);
    return await response.json();
  };

  return useQuery({
    queryKey: ["seed", slug],
    queryFn,
  });
};

export default useSeed;
