import { useMutation } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";

const API = process.env.REACT_APP_API;

const useResetPassword = () => {
  const [searchParams] = useSearchParams();
  const mutationFn = async (password: string) => {
    const response = await fetch(`${API}/api/reset-password`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ password, token: searchParams.get("token") }),
    });
    return await response.json();
  };

  return useMutation({
    mutationFn,
  });
};

export default useResetPassword;
