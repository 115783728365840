import DeleteIcon from "@mui/icons-material/Delete";
import { FC, useContext } from "react";

import { ICartContext, IItem } from "../../types/cart";
import styles from "./styles.module.css";
import { CartContext } from "../../contexts/CartContext";
import { Box, IconButton } from "@mui/material";
import { useParams } from "react-router-dom";

interface Props {
  item: IItem;
}

const CartItem: FC<Props> = ({ item }) => {
  const { removeItem } = useContext(CartContext) as ICartContext;
  const { slug } = useParams();

  const handleClick = () => {
    if (slug) {
      removeItem(item.product, slug);
    }
  };

  return (
    <>
      <Box>
        <IconButton onClick={handleClick}>
          <DeleteIcon />
        </IconButton>
      </Box>
      <div>{item.amount}</div>
      <div className={styles.item_name}>
        {item.product.base_product.name} - {item.product.producer.name}
      </div>
      <div>R$ {(item.amount * parseFloat(item.product.price)).toFixed(2)}</div>
    </>
  );
};

export default CartItem;
