import { useEffect, useState } from "react";

const setLocalStorage = (key: string, value: any) => {
  window.localStorage.setItem(key, JSON.stringify(value));
};

const getLocalStorage = (key: string, initialValue: any) => {
  try {
    const value = window.localStorage.getItem(key);
    return value ? JSON.parse(value) : initialValue;
  } catch (e) {
    return initialValue;
  }
};

function usePersistentState<T>(key: string, initialValue: T) {
  const [state, setState] = useState(() => getLocalStorage(key, initialValue));

  useEffect(() => {
    setLocalStorage(key, state);
  }, [key, state]);

  return [state, setState];
}

export default usePersistentState;
