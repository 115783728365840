import { Grid, Typography } from "@mui/material";
import { FC } from "react";
import { ICategory, IProduct } from "../../types/cart";
import Product from "./Product";

interface Props {
  category: ICategory;
}

const Category: FC<Props> = ({ category }) => (
  <Grid item xs={12}>
    <Typography
      variant="h4"
      component="h2"
      gutterBottom
      sx={{
        backgroundColor: "#447737",
        borderRadius: "0.5rem",
        color: "#f5f2e5",
        padding: "1rem",
      }}
    >
      {category.name}
    </Typography>
    <Grid alignItems="stretch" container item spacing={2}>
      {category.products.map((product: IProduct) => (
        <Product key={product.id} product={product} />
      ))}
    </Grid>
  </Grid>
);

export default Category;
