import { useQuery } from "@tanstack/react-query";

const API = process.env.REACT_APP_API;

const useSeeds = () => {
  const queryFn = async () => {
    const response = await fetch(`${API}/api/seeds`);
    return await response.json();
  };

  return useQuery({
    queryKey: ["seeds"],
    queryFn,
  });
};

export default useSeeds;
