import { Box, Button, TextField, Typography } from "@mui/material";
import { ChangeEvent, FormEvent, useState } from "react";
import useSendResetPasswordEmail from "../../services/useSendResetPasswordEmail";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const { mutate } = useSendResetPasswordEmail();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    mutate(email);
  };

  return (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        height: "100vh",
        justifyContent: "center",
      }}
    >
      <Typography variant="body1">
        Preencha seu email para te enviaremos um link para registro de uma nova
        senha
      </Typography>
      <form onSubmit={handleSubmit}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <TextField
            label="E-mail"
            onChange={handleChange}
            required
            value={email}
          />
          <Button
            disabled={email === ""}
            onClick={handleSubmit}
            type="submit"
            variant="contained"
          >
            Enviar
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default ForgotPassword;
