import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";
import { FC } from "react";
import { IProduct } from "../../types/cart";
import AmountButton from "./AmountButton";

const formatPrice = (price: string) => `R$ ${price.replace(".", ",")}`;

interface Props {
  product: IProduct;
}

const Product: FC<Props> = ({ product }) => (
  <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
      }}
    >
      <CardMedia
        image={product.base_product.image}
        sx={{ height: "10rem" }}
        title={product.base_product.name + " - " + product.producer.name}
      />
      <CardContent sx={{ flex: "1 0 auto" }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" component="h3">
              {product.base_product.name} - {product.producer.name}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              {formatPrice(product.price)}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <AmountButton product={product} />
      </CardActions>
    </Card>
  </Grid>
);

export default Product;
