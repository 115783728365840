import { useQuery } from "@tanstack/react-query";

import { useParams } from "react-router-dom";

const API = process.env.REACT_APP_API;

const useProducts = () => {
  const { slug } = useParams();
  const queryFn = async () => {
    const response = await fetch(`${API}/api/products?slug=${slug}`);
    return await response.json();
  };

  return useQuery({
    queryKey: ["products"],
    queryFn,
  });
};

export default useProducts;
