import { ChangeEvent, FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";

import styles from "./styles.module.css";
import {
  Alert,
  Box,
  Button,
  Collapse,
  TextField,
  Typography,
} from "@mui/material";
import useSignup from "../../services/useSignup";

const Signup = () => {
  const navigate = useNavigate();
  const [form, setForm] = useState({
    address: "",
    email: "",
    name: "",
    password: "",
    passwordConfirmation: "",
    phone: "",
  });
  const { isError, isLoading, isSuccess, mutate, reset } = useSignup();

  const handleChange =
    (
      key:
        | "address"
        | "email"
        | "name"
        | "password"
        | "passwordConfirmation"
        | "phone",
    ) =>
    (e: ChangeEvent<HTMLInputElement>) => {
      setForm({
        ...form,
        [key]: e.target.value,
      });
    };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    mutate(
      {
        address: form.address,
        email: form.email,
        name: form.name,
        password: form.password,
        phone: form.phone,
      },
      {
        onSuccess(data, variables, context) {
          setTimeout(() => {
            navigate("/");
          }, 2000);
        },
        onError(error, variables, context) {
          console.log(error);
        },
      },
    );
  };

  const closeAlert = () => {
    reset();
  };

  const isDisabled =
    isLoading ||
    form.address === "" ||
    form.email === "" ||
    form.name === "" ||
    form.password === "" ||
    form.phone === "" ||
    form.password !== form.passwordConfirmation;

  return (
    <div>
      <Box
        sx={{
          position: "fixed",
          top: "0",
          width: "100%",
        }}
      >
        <Collapse in={isError}>
          <Alert severity="error" onClose={closeAlert}>
            Houve um problema ao cadastrar seu usuário, tente novamente.
          </Alert>
        </Collapse>
        <Collapse in={isSuccess}>
          <Alert severity="success" onClose={closeAlert}>
            Usuário cadastrado com sucesso
          </Alert>
        </Collapse>
      </Box>
      <div className={styles.container}>
        <div className={styles.innerContainer}>
          <Typography variant="h5" component="h1">
            Olá, parceira!
          </Typography>
          <Typography variant="body1" component="h1">
            Cadastre-se para começar a montar sua cesta da semana!
          </Typography>
          <form className={styles.form} onSubmit={handleSubmit}>
            <TextField
              label="Nome"
              onChange={handleChange("name")}
              required
              value={form.name}
            />
            <TextField
              label="Telefone"
              onChange={handleChange("phone")}
              required
              value={form.phone}
            />
            <TextField
              label="Endereço"
              onChange={handleChange("address")}
              required
              value={form.address}
            />
            <TextField
              label="E-mail"
              onChange={handleChange("email")}
              required
              value={form.email}
            />
            <TextField
              label="Senha"
              onChange={handleChange("password")}
              required
              type="password"
              value={form.password}
            />
            <TextField
              label="Confirmar senha"
              onChange={handleChange("passwordConfirmation")}
              required
              type="password"
              value={form.passwordConfirmation}
            />
            <Button
              disabled={isDisabled}
              onClick={handleSubmit}
              type="submit"
              variant="contained"
            >
              Cadastrar
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Signup;
