export type DeliveryMethod = "delivery" | "takeout" | null;

export interface IDeliveryContext {
  address: string | null;
  deliveryMethod: DeliveryMethod;
  setAddress: (address: string | null) => void;
  setDeliveryMethod: (deliveryMethod: DeliveryMethod) => void;
}

export function isDeliveryMethod(val: any): val is DeliveryMethod {
  return val === "delivery" || val === "takeout" || val === null;
}
