import { Box, Button, CircularProgress, Typography } from "@mui/material";

import styles from "./styles.module.css";
import useUrbanPartner from "../../services/useUrbanPartner";
import useSeed from "../../services/useSeed";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { getWeekdayName } from "../../utils/weekdayUtils";

const Payment = () => {
  const { isLoading: isLoadingUP } = useUrbanPartner();
  const { data: seed, isLoading: isLoadingSeed } = useSeed();
  const [searchParams] = useSearchParams();
  const { slug } = useParams();

  const totalParam = searchParams.get("total");
  const totalAmount =
    totalParam !== null ? parseFloat(totalParam).toFixed(2) : "0";
  const deliveryParam = searchParams.get("delivery");

  if (isLoadingUP || isLoadingSeed || !seed) {
    return <CircularProgress />;
  }

  if (!searchParams.has("total") || !searchParams.get("total")) {
    return (
      <div style={{ margin: "0 auto", maxWidth: "900px", padding: "2rem" }}>
        <Typography variant="h4" component="h2" gutterBottom>
          Pagamento
        </Typography>
        <Typography variant="body1" gutterBottom>
          Valor total nulo ou não identificado, tente novamente.
        </Typography>

        <Box textAlign="center">
          <Link to={`/${slug}`}>
            <Button className={styles.btn}>Voltar para a loja</Button>
          </Link>
        </Box>
      </div>
    );
  }

  if (totalParam !== null && parseFloat(totalParam) === 0) {
    return (
      <div style={{ margin: "0 auto", maxWidth: "900px", padding: "2rem" }}>
        <Typography variant="h4" component="h2" gutterBottom>
          Seu pedido já está pago
        </Typography>
        <Typography variant="body1">Pronto!</Typography>
        <Typography variant="body1">
          Agora sua semeadora cuidará da sua feira pra você.
        </Typography>

        <Box textAlign="center">
          <Link to={`/${slug}`}>
            <Button className={styles.btn}>Voltar para a loja</Button>
          </Link>
        </Box>
      </div>
    );
  }

  const deliveryWeekday = getWeekdayName(seed.delivery_weekday);
  let pixKey = seed.seeder.pix_key;
  if (/\+55\d{10,11}/.test(pixKey)) {
    pixKey = pixKey.replace("+55", "");
  }

  return (
    <div style={{ margin: "0 auto", maxWidth: "900px", padding: "2rem" }}>
      <Typography variant="h4" component="h2" gutterBottom>
        Pagamento
      </Typography>
      <Typography variant="body1" gutterBottom>
        Agora só fazer o PIX pra sua semeadora:
      </Typography>
      <div className={styles.seeder}>
        <Typography variant="body2">
          Valor do seu pedido: R$ {totalAmount}
        </Typography>
        <Typography variant="body2">
          Chave PIX: <strong>{pixKey}</strong>
        </Typography>
        <Typography variant="body2">Nome: {seed.seeder.name}</Typography>
        <Typography variant="body2">Banco: C6</Typography>
      </div>
      <Typography variant="body1">Pronto!</Typography>
      {deliveryParam === "delivery" && (
        <Typography variant="body1">
          A entrega está programada para: {deliveryWeekday}.
        </Typography>
      )}
      {deliveryParam === "takeout" && (
        <Typography variant="body1">
          O dia para retirada da sua feira é: {deliveryWeekday}.
        </Typography>
      )}
      <Typography variant="body1">
        Agora sua semeadora cuidará da sua feira pra você.
      </Typography>

      <Box textAlign="center">
        <Link to={`/${slug}`}>
          <Button className={styles.btn}>Voltar para a loja</Button>
        </Link>
      </Box>
    </div>
  );
};

export default Payment;
