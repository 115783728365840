import { useMutation } from "@tanstack/react-query";
import { IAuthContext } from "../types/auth";
import { useContext } from "react";
import { AuthContext } from "../contexts/AuthContext";
import { useNavigate, useSearchParams } from "react-router-dom";

const API = process.env.REACT_APP_API;

const useLogin = (username: string, password: string) => {
  const { setAuthTokens } = useContext(AuthContext) as IAuthContext;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const mutationFn = async () => {
    const response = await fetch(`${API}/api/token/`, {
      method: "POST",
      body: JSON.stringify({ username, password }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      const error = await response.json();
      error.status = response.status;
      throw error;
    }

    return await response.json();
  };

  return useMutation({
    mutationKey: ["login"],
    mutationFn,
    onSuccess: (data) => {
      if (data) {
        setAuthTokens(data);
        let to = "/";

        if (searchParams.has("slug")) {
          to += searchParams.get("slug");
        }

        if (searchParams.has("page")) {
          to += `/${searchParams.get("page")}`;
        }

        navigate(to, { replace: true });
      }
    },
  });
};

export default useLogin;
