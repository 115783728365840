import { Button, Drawer, Fab, IconButton, Typography } from "@mui/material";
import { useContext, useState } from "react";
import Items from "../../components/Items";
import { Close, ShoppingCart } from "@mui/icons-material";
import { Link, useParams } from "react-router-dom";
import styles from "./styles.module.css";
import { CartContext } from "../../contexts/CartContext";
import { ICartContext } from "../../types/cart";

const Cart = () => {
  const [open, setOpen] = useState(false);
  const { getCart } = useContext(CartContext) as ICartContext;
  // const { data: urbanPartner, isLoading } = useUrbanPartner();
  const { slug } = useParams();

  const cart = getCart(slug);

  const items = Object.keys(cart).map((productId) => cart[parseInt(productId)]);
  const total = items.reduce((total, item) => {
    return total + item.amount * parseFloat(item.product.price);
  }, 0);

  // const credit = urbanPartner ? parseFloat(urbanPartner.credit) : 0;
  const totalItems = items.length;

  // if (isLoading) {
  //   return null;
  // }

  return (
    <>
      <Drawer
        anchor="right"
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        PaperProps={{
          sx: {
            boxSizing: "border-box",
            padding: "1rem",
            overflowWrap: "break-word",
            width: "22rem",
          },
        }}
      >
        <div className={styles.close_container}>
          <IconButton
            onClick={() => {
              setOpen(false);
            }}
          >
            <Close />
          </IconButton>
        </div>
        <Typography variant="h5" component="h4" gutterBottom>
          Meus itens
        </Typography>
        <Items />
        {/* urbanPartner.credit > 0 && (
          <Typography
            sx={{ textAlign: "right" }}
            variant="subtitle1"
            component="div"
          >
            Crédito: R$ {credit.toFixed(2)}
          </Typography>
        ) */}
        <Typography sx={{ textAlign: "right" }} variant="h6" component="div">
          {/* {Total: R$ {(credit > total) ? 0 : (total - credit).toFixed(2)}} */}
          Total: R$ {total.toFixed(2)}
        </Typography>
        {totalItems > 0 ? (
          <Link to="checkout">
            <Button variant="contained" sx={{ width: "100%" }}>
              Tudo certo!
            </Button>
          </Link>
        ) : null}
      </Drawer>
      <Fab
        aria-label="cart"
        color="primary"
        onClick={() => {
          setOpen(true);
        }}
        sx={{
          position: "fixed",
          right: "2rem",
          top: "5rem",
        }}
      >
        <ShoppingCart />
      </Fab>
    </>
  );
};

export default Cart;
