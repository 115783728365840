import { Box, Button, TextField, Typography } from "@mui/material";
import { ChangeEvent, FormEvent, useState } from "react";
import useResetPassword from "../../services/useResetPassword";

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const { mutate } = useResetPassword();

  const handleChangePassword = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleChangePasswordConfirmation = (
    e: ChangeEvent<HTMLInputElement>,
  ) => {
    setPasswordConfirmation(e.target.value);
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (password === passwordConfirmation) {
      mutate(password);
    }
  };

  return (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        height: "100vh",
        justifyContent: "center",
      }}
    >
      <Typography variant="body1">Preencha sua nova senha</Typography>
      <form onSubmit={handleSubmit}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <TextField
            label="Senha"
            onChange={handleChangePassword}
            required
            type="password"
            value={password}
          />
          <TextField
            label="Confirmar senha"
            onChange={handleChangePasswordConfirmation}
            required
            type="password"
            value={passwordConfirmation}
          />
          <Button
            disabled={password === "" || password !== passwordConfirmation}
            onClick={handleSubmit}
            type="submit"
            variant="contained"
          >
            Salvar
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default ResetPassword;
