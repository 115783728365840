import { FC, ReactNode, createContext } from "react";

import usePersistentState from "../hooks/usePersistentState";
import { IAuthContext, ITokens } from "../types/auth";

export const AuthContext = createContext<IAuthContext | null>(null);

interface Props {
  children: ReactNode;
}

const AuthProvider: FC<Props> = ({ children }) => {
  const [tokens, setTokens] = usePersistentState<ITokens>("auth", {
    access: null,
    refresh: null,
  });

  const setAuthTokens = (tokens: ITokens) => {
    setTokens({
      access: tokens.access ? tokens.access : null,
      refresh: tokens.refresh ? tokens.refresh : null,
    });
  };

  const clearAuthTokens = () => {
    setTokens({
      access: null,
      refresh: null,
    });
  };

  return (
    <AuthContext.Provider
      value={{
        tokens,
        setAuthTokens,
        clearAuthTokens,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
