import { useMutation } from "@tanstack/react-query";

import useFetch from "../lib/useFetch";
import { IOrder } from "../types/order";

const API = process.env.REACT_APP_API;

const useCreateOrder = (order: IOrder) => {
  const fetch = useFetch(`${API}/api/orders`, {
    method: "POST",
    body: order,
  });

  return useMutation({
    mutationFn: fetch,
  });
};

export default useCreateOrder;
