import { FC, ReactNode, createContext } from "react";

import { DeliveryMethod, IDeliveryContext } from "../types/delivery";
import usePersistentState from "../hooks/usePersistentState";

export const DeliveryContext = createContext<IDeliveryContext | null>(null);

interface Props {
  children: ReactNode;
}

const DeliveryProvider: FC<Props> = ({ children }) => {
  const [address, setAddress] = usePersistentState<string | null>(
    "address",
    null,
  );
  const [deliveryMethod, setDeliveryMethod] =
    usePersistentState<DeliveryMethod>("deliveryMethod", null);

  return (
    <DeliveryContext.Provider
      value={{
        address,
        deliveryMethod,
        setAddress,
        setDeliveryMethod,
      }}
    >
      {children}
    </DeliveryContext.Provider>
  );
};

export default DeliveryProvider;
