import { Box } from "@mui/material";
import useSeeds from "../../services/useSeeds";
import { ISeed } from "../../types/seed";
import { Link } from "react-router-dom";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";

import L from "leaflet";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconAnchor: [12.5, 41],
});

L.Marker.prototype.options.icon = DefaultIcon;

const Seeds = () => {
  const { data: seeds, isLoading } = useSeeds();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const center = seeds.reduce(
    (acc: [number, number], seed: ISeed, index: number) => {
      acc = [acc[0] + seed.latitude, acc[1] + seed.longitude];
      if (index === seeds.length - 1) {
        acc = [acc[0] / seeds.length, acc[1] / seeds.length];
      }

      return acc;
    },
    [0, 0],
  );

  return (
    <Box
      sx={{
        height: "100vh",
        width: "100vw",
      }}
    >
      <MapContainer
        center={center}
        zoom={12}
        style={{ height: "100%", width: "100%" }}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {seeds.map((seed: ISeed) => (
          <Marker key={seed.slug} position={[seed.latitude, seed.longitude]}>
            <Popup>
              <Link to={seed.slug}>
                {seed.name} - {seed.address}
              </Link>
            </Popup>
          </Marker>
        ))}
      </MapContainer>
    </Box>
  );
};

export default Seeds;
