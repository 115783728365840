import { Box } from "@mui/material";
import useConfirmEmail from "../../services/useConfirmEmail";
import { useEffect } from "react";
import { Link } from "react-router-dom";

const EmailConfirmation = () => {
  const { isLoading, mutate, isSuccess } = useConfirmEmail();

  useEffect(() => {
    mutate();
  }, [mutate]);

  return (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        height: "100vh",
        justifyContent: "center",
      }}
    >
      {isLoading ? "Confirmando seu email" : null}
      {isSuccess ? (
        <Box>
          Email confirmado com sucesso, faça login{" "}
          <Box
            sx={{
              color: "primary.main",
              display: "inline",
              textDecoration: "underline",
            }}
          >
            <Link to="/login">aqui</Link>
          </Box>
        </Box>
      ) : null}
    </Box>
  );
};

export default EmailConfirmation;
