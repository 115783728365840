export const weekdayMap = {
  SUN: 0,
  MON: 1,
  TUE: 2,
  WED: 3,
  THU: 4,
  FRI: 5,
  SAT: 6,
};

type WeekdayName =
  | "domingo"
  | "segunda-feira"
  | "terça-feira"
  | "quarta-feira"
  | "quinta-feira"
  | "sexta-feira"
  | "sábado";

interface WeekdayNameMap {
  [key: string]: WeekdayName;
}

export const weekdayNameMap: WeekdayNameMap = {
  SUN: "domingo",
  MON: "segunda-feira",
  TUE: "terça-feira",
  WED: "quarta-feira",
  THU: "quinta-feira",
  FRI: "sexta-feira",
  SAT: "sábado",
};

export function isWeekdayMapKey(val: any): val is keyof typeof weekdayMap {
  return Object.keys(weekdayMap).includes(val);
}

export function getWeekdayName(key: keyof typeof weekdayNameMap): WeekdayName {
  return weekdayNameMap[key];
}
