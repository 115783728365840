import { AppBar, Box, Toolbar } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import LoginIcon from "@mui/icons-material/Login";
import styles from "./styles.module.css";
import { useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { IAuthContext } from "../../types/auth";
import NavButton from "./NavButton";
import { DeliveryContext } from "../../contexts/DeliveryContext";
import { IDeliveryContext } from "../../types/delivery";
import { CartContext } from "../../contexts/CartContext";
import { ICartContext } from "../../types/cart";
import useUrbanPartner from "../../services/useUrbanPartner";
import { useQueryClient } from "@tanstack/react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const NavigationBar = () => {
  const { clearAuthTokens, tokens } = useContext(AuthContext) as IAuthContext;
  const { setDeliveryMethod, setAddress } = useContext(
    DeliveryContext,
  ) as IDeliveryContext;
  const { emptyCart } = useContext(CartContext) as ICartContext;
  const { data: urbanPartner } = useUrbanPartner();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const { slug } = useParams();

  const handleLogout = () => {
    emptyCart();
    setDeliveryMethod(null);
    setAddress(null);
    clearAuthTokens();
    queryClient.removeQueries({ queryKey: ["urban-partner"] });
  };

  const goToLogin = () => {
    const splittedPathname = location.pathname.split(slug + "/");
    const page = splittedPathname.length > 1 ? splittedPathname[1] : "";
    navigate(`/login?slug=${slug}&page=${page}`);
  };

  return (
    <div>
      <AppBar position="static">
        <Toolbar sx={{ position: "relative" }}>
          <div className={styles.centeredElement}>
            <img alt="logo" className={styles.logo} src="/logo.png" />
          </div>
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            {urbanPartner ? <div>Olá, {urbanPartner.name}!</div> : null}
            {tokens.access !== null ? (
              <NavButton edge="end" onClick={handleLogout}>
                <LogoutIcon />
              </NavButton>
            ) : (
              <NavButton edge="end" onClick={goToLogin}>
                <LoginIcon />
              </NavButton>
            )}
          </Box>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default NavigationBar;
