import { useMutation } from "@tanstack/react-query";

const API = process.env.REACT_APP_API;

const useSignup = () => {
  const mutationFn = async ({
    address,
    email,
    name,
    password,
    phone,
  }: {
    address: string;
    email: string;
    name: string;
    password: string;
    phone: string;
  }) => {
    const response = await fetch(`${API}/api/urban-partners`, {
      method: "POST",
      body: JSON.stringify({
        address,
        name,
        phone,
        user: {
          password,
          username: email,
        },
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw await response.json();
    }

    return await response.json();
  };

  return useMutation({
    mutationFn,
  });
};

export default useSignup;
