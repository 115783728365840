import { useMutation } from "@tanstack/react-query";

const API = process.env.REACT_APP_API;

const useSendResetPasswordEmail = () => {
  const mutationFn = async (email: string) => {
    const response = await fetch(`${API}/api/send-reset-password-email`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    });
    return await response.json();
  };

  return useMutation({
    mutationFn,
  });
};

export default useSendResetPasswordEmail;
