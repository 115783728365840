import useProducts from "../../services/useProducts";
import { useState } from "react";
import { groupBy, sortBy } from "lodash";
import {
  Autocomplete,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import Category from "./Category";
import Cart from "./Cart";
import useSeed from "../../services/useSeed";
import dayjs, { Dayjs } from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import utc from "dayjs/plugin/utc";
import { IProduct } from "../../types/order";
import Product from "./Product";
import {
  weekdayNameMap,
  weekdayMap,
  isWeekdayMapKey,
} from "../../utils/weekdayUtils";
import { Option } from "../../types/order";

dayjs.extend(isBetween);
dayjs.extend(utc);

const setTime = (moment: dayjs.Dayjs, time: string) => {
  const timeRegexp = /\d\d:\d\d:\d\d/;

  if (!timeRegexp.test(time)) {
    throw Error("time must have the format \\d\\d:\\d\\d:\\d\\d");
  }

  return moment
    .hour(parseInt(time.split(":")[0]))
    .minute(parseInt(time.split(":")[1]))
    .second(parseInt(time.split(":")[2]));
};

const getWeekdayDifferenceInDays = (minuend: number, subtrahend: number) => {
  if (minuend < 0 || minuend > 6 || subtrahend < 0 || subtrahend > 6) {
    throw Error("weekdays be between 0 and 6");
  }

  return (minuend - subtrahend + 7) % 7;
};

const getStartMoment = (startWeekday: string, startTime: string) => {
  if (!isWeekdayMapKey(startWeekday)) {
    throw Error(
      `startWeekday must be one of ${Object.keys(weekdayMap).join(", ")}`,
    );
  }

  const now = dayjs.utc();
  const startMoment = setTime(
    now.subtract(
      getWeekdayDifferenceInDays(now.day(), weekdayMap[startWeekday]),
      "day",
    ),
    startTime,
  );

  return startMoment;
};

const getEndMoment = (
  startMoment: Dayjs,
  startWeekday: string,
  endWeekday: string,
  endTime: string,
) => {
  if (!isWeekdayMapKey(startWeekday) || !isWeekdayMapKey(endWeekday)) {
    throw Error(
      `startWeekday and endWeekday must be one of ${Object.keys(
        weekdayMap,
      ).join(", ")}`,
    );
  }

  const endMoment = setTime(
    startMoment.add(
      getWeekdayDifferenceInDays(
        weekdayMap[endWeekday],
        weekdayMap[startWeekday],
      ),
      "day",
    ),
    endTime,
  );

  return endMoment;
};

const isNowBetweenInterval = (
  startWeekday: string,
  startTime: string,
  endWeekday: string,
  endTime: string,
) => {
  const startMoment = getStartMoment(startWeekday, startTime);
  const endMoment = getEndMoment(
    startMoment,
    startWeekday,
    endWeekday,
    endTime,
  );
  const now = dayjs.utc();

  return now.isBetween(startMoment, endMoment);
};

const Store = () => {
  // const navigate = useNavigate();
  // const { tokens } = useContext(AuthContext) as IAuthContext;
  const { data: products, isLoading: isLoadingProducts } = useProducts();
  const { data: seed, isLoading: isLoadingSeed } = useSeed();
  const [item, setItem] = useState<null | string | Option>(null);

  // useEffect(() => {
  //   if (tokens.access === null && tokens.refresh === null) {
  //     navigate("/login", { replace: true });
  //   }
  // }, [navigate, tokens]);

  if (isLoadingProducts || isLoadingSeed) {
    return <CircularProgress />;
  }

  if (!products) {
    return null;
  }

  const groupedProducts = products.map((product: IProduct) => ({
    name: product.base_product.categories[0].name,
    order: product.base_product.categories[0].order,
    product,
  }));

  const sortedCategories = sortBy(groupedProducts, ["order", "name"]);
  const productsByCategories = groupBy(sortedCategories, "name");

  const categories = Object.keys(productsByCategories).map((categoryName) => ({
    name: categoryName,
    products: sortBy(
      productsByCategories[categoryName].map((item) => item.product),
      "base_product.name",
    ),
  }));

  const filtered = products.filter((product: IProduct) => {
    if (!item) {
      return true;
    }

    if (typeof item === "string") {
      const tokens = item.split(" ");
      return tokens
        .map((token) => token.toLowerCase())
        .every((token) => {
          const productName = product.base_product.name.toLowerCase();
          const producer = product.producer.name.toLowerCase();
          return productName.includes(token) || producer.includes(token);
        });
    }

    return product.id === item.value;
  });

  const {
    start_orders_weekday,
    start_orders,
    stop_orders_weekday,
    stop_orders,
  } = seed;

  if (
    !isWeekdayMapKey(start_orders_weekday) ||
    !isWeekdayMapKey(stop_orders_weekday)
  ) {
    return null;
  }

  const startMoment = getStartMoment(start_orders_weekday, start_orders);
  const endMoment = getEndMoment(
    startMoment,
    start_orders_weekday,
    stop_orders_weekday,
    stop_orders,
  );

  const onItemFilter: (_: any, newValue: null | string | Option) => void = (
    _,
    newValue,
  ) => {
    setItem(newValue);
  };

  const items: Option[] = products.map((product: IProduct) => ({
    label: `${product.base_product.name} - ${product.producer.name}`,
    value: product.id,
  }));

  const sortedItems = sortBy(items, "label");

  return (
    <div>
      {!isNowBetweenInterval(
        start_orders_weekday,
        start_orders,
        stop_orders_weekday,
        stop_orders,
      ) ? (
        <Dialog
          open={true}
          aria-labelledby="dialog-title"
          aria-describedby="dialog-description"
        >
          <DialogTitle id="dialog-title">Feira fechada</DialogTitle>
          <DialogContent>
            <DialogContentText id="dialog-description">
              O horário de funcionamento da feira é de{" "}
              {weekdayNameMap[start_orders_weekday]} às{" "}
              {startMoment.local().format("HH:mm")} até{" "}
              {weekdayNameMap[stop_orders_weekday]} às{" "}
              {endMoment.local().format("HH:mm")}.
            </DialogContentText>
          </DialogContent>
        </Dialog>
      ) : null}
      <Box sx={{ p: "2rem" }}>
        <Box sx={{ mb: 6 }}>
          <Autocomplete
            value={item}
            onChange={onItemFilter}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            disablePortal
            options={sortedItems}
            sx={{ width: "50%" }}
            renderInput={(params) => (
              <TextField {...params} label="Pesquisar" />
            )}
            freeSolo
          />
        </Box>
        <Grid container spacing={4}>
          {item !== null
            ? filtered.map((product: IProduct) => (
                <Product product={product} key={product.id} />
              ))
            : categories.map((category) => (
                <Category category={category} key={category.name} />
              ))}
        </Grid>
        <Cart />
      </Box>
    </div>
  );
};

export default Store;
